.Button {
  height: 100%;
}

.Button img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.Button img:hover {
  cursor: pointer;
  filter: drop-shadow(4px 10px 8px #3c3c3c);
}

@media only screen and (max-width: 600px) {
  .Button {
    height: 33%;
    display: flex;
    justify-content: space-around;
  }

  .Button img {
    max-width: 50%;
  }
}
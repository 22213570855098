.DrawingPage {
  height: 100vh;
  width: 100vw;
  padding-top: 10%;
  justify-content: center;
}

.drawingPage {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawing {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.drawing img {
  max-height: 80%;
  max-width: 100%;
  object-fit: contain;
}

.backButton {
  height: 2rem;
  width: 6rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background-color: rgb(255, 255, 255);
  filter: drop-shadow(10px 10px 14px #d5d5d5);
}

.backButton:hover {
  filter: drop-shadow(4px 5px 6px #797979);
  cursor: pointer;
}

.text {
  width: 30rem;
  margin-left: 2rem;
  margin-top: 4rem;
  align-items: center;
}

.text p {
  font-size: 2rem;
}

.text a {
  text-decoration: none;
}

.bookingButton {
  height: 20vh;
}

@media only screen and (max-width: 1400px) {
  .DrawingPage {
    height: auto;
    padding: 0;
  }
  .drawingPage {
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    flex-direction: column;
  }

  .drawing {
    height: 60vh;
    width: 100%;
  }
  
  .drawing img {
    padding-inline: 1rem;
  }

  .backButton {
    margin-left: 1rem;
  }

  .text {
    max-width: 100%;
    margin: 0;
  }
  
  .text p {
    font-size: 2rem;
    padding: 1rem;
  }
  
  .text a {
    text-decoration: none;
  }
  
  .bookingButton {
    height: 40vh;
  }
}

.Nav {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .Nav {
    display: flex;
    flex-direction: column;
  }
}
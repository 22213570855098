.BackToTop {
  display: flex;
  position: fixed;
  right: 5%;
  bottom: 5%;
  border-radius: 10%;
  padding: 0.5rem;
  text-align: center;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  background-color: #ffffff;
  filter: drop-shadow(10px 10px 14px #959595);
}

.BackToTop:hover {
  filter: drop-shadow(4px 10px 9px #2b2b2b);
}

.icon * {
  margin: -0.6rem;
}

.BackToTop p {
  font-size: 1.1rem;
}

@media only screen and (max-width: 600px) {
  .BackToTop{
    font-size: 2.3rem;
  }
  .BackToTop p {
    font-size: 0.9rem;
  }
}
.Logo {
  height: 100%;
  padding-inline: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Logo img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

h1 {
  font-size: 4rem;
}

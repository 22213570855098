.Booking {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Booking img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.Booking a {
  height: 100%;
}

.Booking img:hover {
  cursor: pointer;
  filter: drop-shadow(4px 10px 8px #3c3c3c);
}

// @media only screen and (max-width: 600px) {
//   .Booking img {
//     height: 60%;
//   }
// }

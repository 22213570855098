.Drawing {
  height: 12rem;
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.Drawing img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.Drawing img:hover {
  cursor: pointer;
  filter: drop-shadow(4px 10px 8px #3c3c3c);
}

@media only screen and (max-width: 1400px) {
  .Drawing {
    height: 10rem;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .Drawing {
    height: 8rem;
    margin: 0.2rem;
  }
}
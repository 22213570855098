.Header {
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  height: 80%;
  width: 40%;
}

.logo {
  height: 38%;
  margin-bottom: 2%;
}

.nav {
  height: 24%;
  margin-bottom: 2%;
}

.booking {
  height: 38%;
}

@media only screen and (max-width: 1400px) {
  .header {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .Header {
    height: 75vh;
    padding-top: 3rem;
  }
  .header {
    height: 100%;
  }
  .logo {
    height: 25%;
    margin: 0;
  }
  .nav {
    height: 45%;
    margin: 0;
  }
  .booking {
    height: 25%;
  }
}

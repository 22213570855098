.Canvas {
  width: 100%;
  display: flex;
  justify-content: center;
  // background-color: rgb(251, 250, 248);
  background-color: #ffffff;
}

.canvas {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 5rem;
  // filter: drop-shadow(10px 10px 14px #b4b4b4);
}

.canvas::after {
  content: "";
  flex: auto;
}

@media only screen and (max-width: 1400px) {
  .canvas {
    min-width: 100%;
    justify-content: center;
  }
}